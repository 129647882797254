<template>
  <div class="dots__container" :class="bodyClass">

      <div class="dots" :class="transition ? 'dots--hidden' : ''" v-show="milestone === 1">
        <div class="dot dot--1" :class="activeModal === 1 ? 'active' : ''" v-on:click="activeModal = 1; closeIntroModal()"></div>
        <div class="dot dot--2" :class="activeModal === 2 ? 'active' : ''" v-on:click="activeModal = 2; closeIntroModal()"></div>
      </div>


    <div class="dots" :class="transition ? 'dots--hidden' : ''" v-show="milestone === 2">
      <div class="dot dot--1" :class="activeModal === 1 ? 'active' : ''" v-on:click="activeModal = 1; closeIntroModal()"></div>
      <div class="dot dot--2" :class="activeModal === 2 ? 'active' : ''" v-on:click="activeModal = 2; closeIntroModal()"></div>
      <div class="dot dot--3" :class="activeModal === 3 ? 'active' : ''" v-on:click="activeModal = 3; closeIntroModal()"></div>
      <div class="dot dot--4" :class="activeModal === 4 ? 'active' : ''" v-on:click="activeModal = 4; closeIntroModal()"></div>
    </div>

    <div class="dots" :class="transition ? 'dots--hidden' : ''" v-show="milestone === 3">
      <div class="dot dot--1" :class="activeModal === 1 ? 'active' : ''" v-on:click="activeModal = 1; closeIntroModal()"></div>
      <div class="dot dot--2" :class="activeModal === 2 ? 'active' : ''" v-on:click="activeModal = 2; closeIntroModal()"></div>
      <div class="dot dot--3" :class="activeModal === 3 ? 'active' : ''" v-on:click="activeModal = 3; closeIntroModal()"></div>
      <div class="dot dot--4" :class="activeModal === 4 ? 'active' : ''" v-on:click="activeModal = 4; closeIntroModal()"></div>
      <div class="dot dot--5" :class="activeModal === 5 ? 'active' : ''" v-on:click="activeModal = 5; closeIntroModal()"></div>
    </div>

    <div class="dots" :class="transition ? 'dots--hidden' : ''" v-show="milestone === 4">
      <div class="dot dot--1" :class="activeModal === 1 ? 'active' : ''" v-on:click="activeModal = 1; closeIntroModal()"></div>
      <div class="dot dot--2" :class="activeModal === 2 ? 'active' : ''" v-on:click="activeModal = 2; closeIntroModal()"></div>
      <div class="dot dot--3" :class="activeModal === 3 ? 'active' : ''" v-on:click="activeModal = 3; closeIntroModal()"></div>
      <div class="dot dot--4" :class="activeModal === 4 ? 'active' : ''" v-on:click="activeModal = 4; closeIntroModal()"></div>
      <div class="dot dot--5" :class="activeModal === 5 ? 'active' : ''" v-on:click="activeModal = 5; closeIntroModal()"></div>
    </div>

    <div class="dots" :class="transition ? 'dots--hidden' : ''" v-show="milestone === 5">
      <div class="dot dot--1" :class="activeModal === 1 ? 'active' : ''" v-on:click="activeModal = 1; closeIntroModal()"></div>
      <div class="dot dot--2" :class="activeModal === 2 ? 'active' : ''" v-on:click="activeModal = 2; closeIntroModal()"></div>
      <div class="dot dot--3" :class="activeModal === 3 ? 'active' : ''" v-on:click="activeModal = 3; closeIntroModal()"></div>
    </div>
    <ModalContainer @openSlider="openSlider" @closeIntroModal="closeIntroModal" @closeModal="closeModal" :milestone="milestone" :activeModal="activeModal" :activeIntroModal="activeIntroModal"></ModalContainer>
    <SliderContainer @closeSlider="closeSlider" :milestone="milestone" :activeSlider="activeSlider"></SliderContainer>
  </div>
</template>

<script>
import ModalContainer from '@/components/ModalContainer'
import SliderContainer from '@/components/SliderContainer'

export default {
  name: 'DotContainer',
  props: ['milestone', 'transition'],
  components: {
    ModalContainer,
    SliderContainer
  },
  data() {
    return{
      activeModal: 0,
      activeIntroModal: true,
      activeSlider: 0,
      milestoneClicked: false,
    }
  },
  computed:{
    bodyClass() {
      let classString = '';
      switch (this.milestone){
        case 0:
          classString = ''
          break;
        case 1:
          classString = 'introduction'
          break;
        case 2:
          classString = 'page--01'
          break;
        case 3:
          classString = 'page--02'
          break;
        case 4:
          classString = 'page--03'
          break;
        case 5:
          classString = 'page--04'
          break;
      }

      return classString
    }
  },
  methods: {
    openSlider(sliderIndex){
      this.activeSlider = sliderIndex;
    },
    closeSlider(){
      this.activeSlider = 0;
      this.activeModal = 0;
    },
    closeIntroModal(){
      this.activeIntroModal = false;
    },
    closeModal(){
      this.activeModal = 0;
    },
  },
  watch: {
    milestone: function(newVal, oldVal){
      console.log(newVal, oldVal)
      let _this = this;
      if(!this.transition){
        setTimeout(function() {
          _this.activeIntroModal = true;
        }, 600);
      }
    },
    transition: function (newVal, oldVal) {
      console.log("Dot Container", newVal, oldVal)
      if(newVal === false){
        let _this = this;
        setTimeout(function() {
          _this.activeIntroModal = true;
        }, 600);

      }
    }
  }
}
</script>
