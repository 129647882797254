<template>

  <div class="modals">
    <transition-group name="fade">
      <div :key="'modal_01'" v-show="milestone === 1">
        <transition name="fade">
          <div class="modal modal--0" v-show="activeModal === 0 && activeIntroModal">
            <div class="modal--close modal__black--close" v-on:click="closeIntroModal()"></div>
            <h2>Einführung</h2>
            <p class="ml-0 font-weight-light">Eine Mauer für die Ewigkeit</p>
            <p class="ml-0 font-weight-light">Beim Neubau des Amts für Umwelt und Energie (AUE) legte die Archäologische Bodenforschung während der Ausgrabungen im Jahr 2019 die hier erhaltene Mauer eines repräsentativen Gebäudes der Zeit um 1250 frei.</p>
          </div>
        </transition>
        <transition name="fade">
          <div class="modal modal--1" v-show="activeModal === 1 ">
            <div class="modal--close" v-on:click="closeModal"></div>
            <p><strong>Eine Mauer für die Ewigkeit</strong><br>
              Die Mauern waren überdurchschnittlich hoch erhalten, weil man das Gelände im Laufe der Zeit mehrfach aufgeschüttet hat. Eine Mauer aus bearbeitetem Sandstein – sogenannten Bossenquadern – stach aufgrund ihrer Machart besonders hervor. Das Mauerstück wurde hier am Originalfundort konserviert.
            </p>
          </div>
        </transition>
        <transition name="fade">
          <div class="modal modal--2" v-show="activeModal === 2">
            <div class="modal--close" v-on:click="closeModal"></div>
            <p><strong>Wertvolle Zeugen der Stadtgeschichte</strong><br>
              Insgesamt 265 Kubikmeter Erdmaterial und rund 170 Tonnen Steine wurden 2019 beim Neubau des Amtes für Umwelt und Energie (AUE) sorgfältig von Hand abgetragen. Dabei wurden zahlreiche Mauern, verfüllte Kellerräume und Reste von mittelalterlichen Holzgebäuden freigelegt.
            </p>
          </div>
        </transition>
      </div>

      <div :key="'modal_02'" v-show="milestone === 2">
        <transition name="fade">
          <div class="modal modal--0" v-show="activeModal === 0 && activeIntroModal">
            <div class="modal--close modal__black--close" v-on:click="closeIntroModal()"></div>
            <h2>Um 300–450 n. Chr.</h2>
            <p class="ml-0 font-weight-light">Eine römische Siedlung</p>
            <p class="ml-0 font-weight-light">
              In spätantiker Zeit entstand am Fuss des befestigten Münsterhügels an der römischen Fernstrasse, die
              dem Rhein entlang bis an die Nordsee führte, eine kleine Siedlung mit einer Schifflände an der
              Birsigmündung.
            </p>
          </div>
        </transition>
        <transition name="fade">
          <div class="modal modal--1" v-show="activeModal === 1">
            <div class="modal--close" v-on:click="closeModal()"></div>
            <p class="has__image"><strong>Ein wichtiger Verkehrsknotenpunkt</strong><br>
              In der Spätantike entstand am Fusse des Münsterhügels eine kleine Siedlung.
              An einer Fernstrasse und zugleich im Bereich der Birsigmündung gelegen, bildete sie einen regionalen und
              überregionalen Verkehrsknotenpunkt,
              an dem verschiedene Handelsrouten zusammenkamen.
              <span class="color-highlight modal--link" v-on:click="openSlider(1)">Mehr erfahren</span>
            </p>
            <div class="relative modal--link--mobile--wrapper">
              <span class="color-highlight modal--link modal--link--mobile" v-on:click="openSlider(1)">Mehr erfahren</span>
              <img rel="preload" src="~@/assets/images/scene01_modal01.jpg" alt="">
            </div>
          </div>
        </transition>
        <transition name="fade">
          <div class="modal modal--2" v-show="activeModal === 2">
            <div class="modal--close" v-on:click="closeModal()"></div>
            <p  class="has__image"><strong>Ein Kamel in Basel</strong><br>
              Funde von Kamel- und Dromedarknochen sind in der Schweiz äusserst selten.
              Mehrere zusammenpassende Knochenfragmente eines Unterkiefers wurden bei Ausgrabungen 1937–39 und 2018 auf
              der gegenüberliegenden Strassenseite beim Spiegelhof gefunden.
              Sie belegen die Anwesenheit eines Kamels im spätantiken Basel.
              <span class="color-highlight modal--link" v-on:click="openSlider(2)">Mehr erfahren</span>
            </p>
            <div class="relative modal--link--mobile--wrapper">
              <span class="color-highlight modal--link modal--link--mobile" v-on:click="openSlider(2)">Mehr erfahren</span>
              <img rel="preload" src="~@/assets/images/scene01_modal02.png" alt="">
            </div>
          </div>
        </transition>
        <transition name="fade">
          <div class="modal modal--3" v-show="activeModal === 3">
            <div class="modal--close" v-on:click="closeModal()"></div>
            <p><strong>Eine Schiffländestelle am Birsig?</strong><br>
              Der Rhein war bereits in römischer Zeit eine wichtige Verkehrsachse. Grosse Warenmengen konnten mit dem
              Schiff bequemer als zu Land transportiert werden. Ab 300 n. Chr. diente der Rhein zudem als Grenze zu dem
              nun wieder germanisch besiedelten Gebiet.
            </p>
          </div>
        </transition>
        <transition name="fade">
          <div class="modal modal--4" v-show="activeModal === 4">
            <div class="modal--close" v-on:click="closeModal()"></div>
            <p><strong>Die Befestigung auf dem Münsterhügel</strong><br>
              Um 300 n. Chr. waren die Römer durch kriegerische Auseinandersetzungen mit den Germanen gezwungen,
              die nördliche Grenze ihres Imperiums an den Rhein zurückzuverlegen.
              Der Basler Münsterhügel wurde mit einer Befestigungsmauer umwehrt und diente als Standort militärischer
              Einheiten und zum Schutz der Zivilbevölkerung.
            </p>
          </div>
        </transition>
      </div>

      <div :key="'modal_03'" v-show="milestone === 3">
        <transition name="fade">
          <div class="modal modal--0" v-show="activeModal === 0 && activeIntroModal">
            <div class="modal--close modal__black--close" v-on:click="closeIntroModal()"></div>
            <h2>Um 1000 n. Chr.</h2>
            <p class="ml-0 font-weight-light">Ein Stadtquartier entsteht</p>
            <p class="ml-0 font-weight-light">Ab dem 9./10. Jahrhundert entwickelte sich zwischen Hangbereich und Birsig
              eine vorerst noch locker bebaute Siedlung aus Holzbauten, die zum Wohnen und Arbeiten dienten.
            </p>
          </div>
        </transition>
        <transition name="fade">
          <div class="modal modal--1" v-show="activeModal === 1">
            <div class="modal--close" v-on:click="closeModal()"></div>
            <p  class="has__image"><strong>Hölzerne Fässer</strong><br>
              Bei den Ausgrabungen wurden die Reste von mehreren Fässern geborgen.
              Darunter befanden sich sowohl kleinere, handliche Gefässe wie auch zwei Meter hohe Transportfässer.
              Die Dauben (Längshölzer) dieser Fässer wurden oft «recycelt» und einer Zweitverwendung zugeführt.
              <span class="color-highlight modal--link" v-on:click="openSlider(1)">Mehr erfahren</span>
            </p>
            <div class="relative modal--link--mobile--wrapper">
              <span class="color-highlight modal--link modal--link--mobile" v-on:click="openSlider(1)">Mehr erfahren</span>
              <img rel="preload" src="~@/assets/images/scene02_modal01.jpg" alt="">
            </div>

          </div>
        </transition>
        <transition name="fade">
          <div class="modal modal--2" v-show="activeModal === 2">
            <div class="modal--close" v-on:click="closeModal()"></div>
            <p class="has__image"><strong>Ein mittelalterliches Quartier</strong><br>
              Ab dem 9./10. Jahrhundert entwickelte sich zwischen Hangbereich und Birsig eine vorerst noch locker
              bebaute
              Siedlung aus Holzbauten, die zum Wohnen und Arbeiten dienten.
              <span class="color-highlight modal--link" v-on:click="openSlider(2)">Mehr erfahren</span>
            </p>
            <div class="relative modal--link--mobile--wrapper">
              <span class="color-highlight modal--link modal--link--mobile" v-on:click="openSlider(2)">Mehr erfahren</span>
              <img rel="preload" src="~@/assets/images/scene02_modal02.jpg" alt="">
            </div>

          </div>
        </transition>
        <transition name="fade">
          <div class="modal modal--3" v-show="activeModal === 3">
            <div class="modal--close" v-on:click="closeModal()"></div>
            <p  class="has__image"><strong>Gerber und Schuhmacher</strong><br>
              Bei den Ausgrabungen im Spiegelhof kamen zahlreiche Reste der Gerberei und des lederverarbeitenden
              Handwerks zum Vorschein. Diese geben einen Einblick in die mittelalterlichen Produktionsprozesse dieser
              Handwerkszweige.
              <span class="color-highlight modal--link" v-on:click="openSlider(3)">Mehr erfahren</span>
            </p>
            <div class="relative modal--link--mobile--wrapper">
              <span class="color-highlight modal--link modal--link--mobile" v-on:click="openSlider(3)">Mehr erfahren</span>
              <img rel="preload" src="~@/assets/images/scene02_modal03.jpg" alt="">
            </div>

          </div>
        </transition>
        <transition name="fade">
          <div class="modal modal--4" v-show="activeModal === 4">
            <div class="modal--close" v-on:click="closeModal()"></div>
            <p><strong>Die Peterskirche</strong><br>
              An der Stelle der heutigen Peterskirche standen mehrere Vorgängerbauten. Der Ursprung der Kirche ist nicht
              bekannt, die älteste Anlage könnte jedoch ins 9. Jahrhundert datieren. Von diesem einfachen ersten
              Kirchenbau wurden Teilbereiche ausgegraben. Sie sind heute unter der Kirche konserviert.
            </p>
          </div>
        </transition>
        <transition name="fade">
          <div class="modal modal--5" v-show="activeModal === 5">
            <div class="modal--close" v-on:click="closeModal()"></div>
            <p><strong>Der Birsig</strong><br>
              Um 1000 n. Chr. wurde wohl damit begonnen den Birsig zu kanalisieren. So konnte zusätzliches Bauland
              gewonnen werden. Das Wasser des Birsigs wurde von vielen Handwerkern, u. a. den Gerbern genutzt, die sich
              deswegen extra hier ansiedelten.
            </p>
          </div>
        </transition>
      </div>

      <div :key="'modal_04'" v-show="milestone === 4">
        <transition name="fade">
          <div class="modal modal--0" v-show="activeModal === 0 && activeIntroModal">
            <div class="modal--close modal__black--close" v-on:click="closeIntroModal()"></div>
            <h2>Um 1250 n. Chr.</h2>
            <p class="ml-0 font-weight-light">Ein repräsentativer Steinbau an der Birsigmündung</p>
            <p class="ml-0 font-weight-light">
              Im 12. und 13. Jahrhundert wurden immer mehr Häuser in Stein gebaut. Die mehrstöckigen, oft quadratischen
              Bauten gehörten vermutlich hier ansässigen Handwerkern und Händlern, die zu Wohlstand gekommen und in die
              städtische Oberschicht aufgestiegen waren.
            </p>
          </div>
        </transition>
        <transition name="fade">
          <div class="modal modal--1" v-show="activeModal === 1">
            <div class="modal--close" v-on:click="closeModal()"></div>
            <p><strong>Die erste Rheinbrücke</strong><br>
              An einer niedrigen Uferpartie nahe der Birsigmündung errichtete man um 1225 die erste Rheinbrücke. Bis ins
              19. Jahrhundert war dies in Basel der einzige feste Übergang über den Rhein. Die heutige Mittlere Brücke
              steht ungefähr an der Stelle dieses mittelalterlichen Rheinübergangs. Sie stammt aus dem Jahr 1905.
            </p>
          </div>
        </transition>
        <transition name="fade">
          <div class="modal modal--2" v-show="activeModal === 2">
            <div class="modal--close" v-on:click="closeModal()"></div>
            <p><strong>Die Innere Stadtmauer</strong><br>
              Zwischen 1200 und 1250 baute man eine neue Befestigung. Diese zweite, sogenannte Innere Stadtmauer diente
              in erster Linie einer besseren Verteidigung. Die Befestigung verlief mehrheitlich nur mit wenigen Metern
              Abstand parallel zur älteren Burkhardschen Stadtmauer, die um 1080 errichtet worden war.
            </p>
          </div>
        </transition>
        <transition name="fade">
          <div class="modal modal--3" v-show="activeModal === 3">
            <div class="modal--close" v-on:click="closeModal()"></div>
            <p  class="has__image"><strong>Frühe Steinbauten</strong><br>
              Ab dem 12. Jahrhundert wurden immer mehr Häuser in Stein gebaut – eine Bautechnik, die man bis dahin
              hauptsächlich bei Sakralbauten und Befestigungen verwendet hatte. Mit der Zeit wurden Steinhäuser nicht
              mehr nur von Adeligen errichtet, sondern auch von Handwerkern und Händlern, die zu Wohlstand gekommen
              waren.
              <span class="color-highlight modal--link ml-0" v-on:click="openSlider(1)">Mehr erfahren</span>
            </p>
            <div class="relative modal--link--mobile--wrapper">
              <span class="color-highlight modal--link modal--link--mobile" v-on:click="openSlider(1)">Mehr erfahren</span>
              <img rel="preload" src="~@/assets/images/scene03_modal01.jpg" alt="">
            </div>
          </div>
        </transition>
        <transition name="fade">
          <div class="modal modal--4" v-show="activeModal === 4">
            <div class="modal--close" v-on:click="closeModal()"></div>
            <p class="has__image"><strong>Die Funde</strong><br>
              Bei den Ausgrabungen kamen zahlreiche Gegenstände aus dem Alltagsleben der ehemaligen Bewohner und
              Bewohnerinnen zum Vorschein. Darunter Reste von Kachelöfen, Keramikgefässe, Schlüssel und
              Spielzeugfiguren.
              <span class="color-highlight modal--link" v-on:click="openSlider(2)">Mehr erfahren</span>
            </p>
            <div class="relative modal--link--mobile--wrapper">
              <span class="color-highlight modal--link modal--link--mobile" v-on:click="openSlider(2)">Mehr erfahren</span>
              <img rel="preload" src="~@/assets/images/scene03_modal02.jpg" alt="">
            </div>

          </div>
        </transition>
        <transition name="fade">
          <div class="modal modal--5" v-show="activeModal === 5">
            <div class="modal--close" v-on:click="closeModal()"></div>
            <p  class="has__image"><strong>Der Birsig</strong><br>
              Der Birsig war im 13. Jahrhundert noch kaum überwölbt. Mehrere Brücken und Stege führten über den
              Bachlauf. Der Stadtfluss brachte Nutzwasser für die Handwerksbetriebe und diente der Entsorgung von Haus-
              und Gewerbeabfällen.
              <span class="color-highlight modal--link" v-on:click="openSlider(3)">Mehr erfahren</span>
            </p>
            <div class="relative modal--link--mobile--wrapper">
              <span class="color-highlight modal--link modal--link--mobile" v-on:click="openSlider(3)">Mehr erfahren</span>
              <img rel="preload" src="~@/assets/images/scene03_modal03.jpg" alt="">
            </div>
          </div>
        </transition>
      </div>

      <div :key="'modal_05'" v-show="milestone === 5">
        <transition name="fade">

          <div class="modal modal--0" v-show="activeModal === 0 && activeIntroModal">
            <div class="modal--close modal__black--close" v-on:click="closeIntroModal()"></div>
            <h2>Um 1900 n. Chr.</h2>
            <p class="ml-0 font-weight-light">Das neue Gesicht der Stadt</p>
            <p class="ml-0 font-weight-light">
              Das Gebiet an der Birsigmündung erfuhr gegen Ende des 19. Jahrhunderts eine tiefgreifende Umgestaltung.
              Die rasante Stadtentwicklung nahm dabei keine Rücksicht auf die über Jahrhunderte gewachsene Bebauung.
              Nahezu hundert Altstadthäuser wurden niedergerissen.
            </p>
          </div>
        </transition>
        <transition name="fade">
          <div class="modal modal--1" v-show="activeModal === 1">
            <div class="modal--close" v-on:click="closeModal()"></div>
            <p class="has__image"><strong>Kurz vor dem Abbruch</strong><br>
              Die mittelalterlich geprägte Bebauung zwischen Schifflände und Fischmarkt musste zu Anfang des 20.
              Jahrhunderts der Modernisierung Platz machen. Vor Abbruch der Gebäude befanden sich an der Stelle des
              heutigen Amts für Umwelt und Energie (AUE) ein Coiffeursalon und Teile des Hotels zur Blume.
              <span class="color-highlight modal--link" v-on:click="openSlider(1)">Mehr erfahren</span>
            </p>
            <div class="relative modal--link--mobile--wrapper">
              <span class="color-highlight modal--link modal--link--mobile" v-on:click="openSlider(1)">Mehr erfahren</span>
              <img rel="preload" src="~@/assets/images/scene04_modal01.jpg" alt="">
            </div>
          </div>
        </transition>
        <transition name="fade">
          <div class="modal modal--2" v-show="activeModal === 2">
            <div class="modal--close" v-on:click="closeModal()"></div>
            <p  class="has__image"><strong>Eine neue Strasse</strong><br>
              Blick von der Schifflände zum Fischmarkt. Das Tram fährt durch die neu angelegte Marktgasse auf dem über
              dem Birsig aufgeschütteten Fahrdamm. Die Fotografie entstand zwischen 1900 und 1904.
              <span class="color-highlight modal--link" v-on:click="openSlider(2)">Mehr erfahren</span>
            </p>
            <div class="relative modal--link--mobile--wrapper">
              <span class="color-highlight modal--link modal--link--mobile" v-on:click="openSlider(2)">Mehr erfahren</span>
              <img rel="preload" src="~@/assets/images/scene04_modal02.jpg" alt="">
            </div>

          </div>
        </transition>
        <transition name="fade">
          <div class="modal modal--3" v-show="activeModal === 3">
            <div class="modal--close" v-on:click="closeModal()"></div>
            <p><strong>Verschwundene Gassen</strong><br>
              Die neue Fahrbahn über dem Birsig lag höher als das Erdgeschossniveau der noch stehenden Häuser an der
              alten Kronen- (links) und Schwanengasse (rechts). Treppenstufen führten von der neuen Marktgasse zu den
              alten Gebäuden hinab und eiserne Geländer schützten vor Unfällen. Die Häuserzeilen der beiden alten Gassen
              entlang des Birsigs wurden bereits einige Jahre zuvor abgebrochen.
            </p>
          </div>
        </transition>
      </div>
    </transition-group>

  </div>
</template>

<script>


export default {
  name: 'ModalContainer',
  props: ['milestone', 'activeIntroModal', 'activeModal'],
  methods: {
    closeIntroModal(){
     this.$emit('closeIntroModal');
    },
    closeModal(){
      this.$emit('closeModal');
    },
    openSlider(sliderIndex){
      this.$emit('openSlider', sliderIndex);
    }
  },
  watch:{
    milestone: function(newVal, oldVal){
      this.$emit('closeModal');
    }
  }
}
</script>

<style scoped>

</style>
