<template>
<div class="video__container ">
  <div class="show__desktop" v-if="!isMobile">
    <transition-group>


    <div :key="'video01'" class="video__loop" v-show="milestone === 0">
      <video preload="auto" muted loop playsinline webkit-playsinline class="video" ref="video00" @loadedmetadata="loadVideo()">
        <img rel="preload" src="~@/assets/images/video__bg-spacer.jpg">
        <source src="~@/assets/video/intro.mp4">
      </video>
    </div>
    <div :key="'video02'" class="video__loop" v-show="milestone === 1">
      <img rel="preload" src="~@/assets/images/scene01-1_background.jpg">
    </div>
    <div :key="'video03'" class="video__loop" v-show="milestone === 2">
      <div class="video__loop--overlay" :class="hideVideoOverlay ? 'invisible' : ''"></div>
      <img  src="~@/assets/images/video__bg-spacer.jpg" rel="preload">
      <video preload="auto" muted loop playsinline webkit-playsinline ref="video02"  @loadedmetadata="loadVideo()">
        <source src="~@/assets/video/02_lebensbild_loop.mp4">
      </video>
    </div>
    <div :key="'video04'" class="video__loop" v-show="milestone === 3">
      <div class="video__loop--overlay" :class="hideVideoOverlay ? 'invisible' : ''"></div>
      <img src="~@/assets/images/scene02_background.jpg"  rel="preload">
      <video preload="auto" muted loop playsinline webkit-playsinline ref="video03"  @loadedmetadata="loadVideo()">
        <source src="~@/assets/video/03_lebensbild_loop.mp4">
      </video>
    </div>
    <div :key="'video05'" class="video__loop" v-show="milestone === 4">
      <div class="video__loop--overlay" :class="hideVideoOverlay ? 'invisible' : ''"></div>
      <img src="~@/assets/images/scene03_background.jpg" rel="preload">
      <video preload="auto" muted loop playsinline webkit-playsinline ref="video04"  @loadedmetadata="loadVideo()">
        <source src="~@/assets/video/04_lebensbild_loop.mp4">
      </video>
    </div>
    <div :key="'video06'" class="video__loop" v-show="milestone === 5">
      <div class="video__loop--overlay">
        <img src="~@/assets/images/scene04_background.jpg" rel="preload">
      </div>
    </div>
    </transition-group>
  </div>


  <div class="show__mobile" v-if="isMobile">

    <div class="video__loop" v-show="milestone === 0">
      <img src="~@/assets/images/video__bg-spacer.jpg" rel="preload">
      <video preload="auto" muted loop playsinline webkit-playsinline class="video" ref="mobilevideo00" @loadedmetadata="loadVideo()">
        <source src="~@/assets/video/mobile/intro.mp4">
      </video>
    </div>
    <div class="video__loop" v-show="milestone === 1">
      <img src="~@/assets/images/scene01-1_background.jpg" rel="preload">
    </div>
    <div class="video__loop" v-show="milestone === 2">
      <div class="video__loop--overlay" :class="hideVideoOverlay ? 'invisible' : ''"></div>
      <img src="~@/assets/images/video__bg-spacer.jpg" rel="preload">
      <video preload="auto" muted loop playsinline webkit-playsinline ref="mobilevideo02"  @loadedmetadata="loadVideo()">
        <source src="~@/assets/video/mobile/02_lebensbild_loop.mp4">
      </video>
    </div>
    <div class="video__loop" v-show="milestone === 3">
      <div class="video__loop--overlay" :class="hideVideoOverlay ? 'invisible' : ''"></div>
      <img src="~@/assets/images/video__bg-spacer.jpg"  rel="preload">
      <video preload="auto" muted loop playsinline webkit-playsinline ref="mobilevideo03"  @loadedmetadata="loadVideo()">
        <source src="~@/assets/video/mobile/03_lebensbild_loop.mp4">
      </video>
    </div>
    <div class="video__loop" v-show="milestone === 4">
      <div class="video__loop--overlay" :class="hideVideoOverlay ? 'invisible' : ''"></div>
      <img src="~@/assets/images/video__bg-spacer.jpg" rel="preload">
      <video preload="auto" muted loop playsinline webkit-playsinline ref="mobilevideo04"  @loadedmetadata="loadVideo()">
        <source src="~@/assets/video/mobile/04_lebensbild_loop.mp4">
      </video>
    </div>
    <div class="video__loop" v-show="milestone === 5">
      <div class="video__loop--overlay">
        <img src="~@/assets/images/scene04_background.jpg" rel="preload">
      </div>
    </div>

  </div>


</div>


</template>

<script>


export default {
  name: 'VideoContainer',
  props: ['milestone'],
  data(){
    return{
      videoStatus: [0,0,0,0],
      isMobile: false
    }
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  methods:{
    changeVideoLoadStatus(index){
      this.videoStatus[index] = 1;
      let passed = true;
      for (let i = 0; i < this.videoStatus.length; i++) {
        if(this.videoStatus[i] == 0){
          passed = false;
          break;
        }
      }
      if(passed){

      }
    },
    loadVideo(){
      this.$emit('videosLoaded')
    },
    onResize () {
      this.isMobile = window.innerWidth < 1024
    }
  },
  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
    this.$nextTick(() => {
      if(this.isMobile){
        this.$refs.mobilevideo00.play();
      } else {
        this.$refs.video00.play();
      }
    })
  },
  computed: {

  },
  watch: {
    milestone: function (newVal, oldVal){
      let ref = 'video0'+newVal;
      if (window.innerWidth < 1024) {
        ref = 'mobilevideo0'+ newVal;
      }
      let refObj = this.$refs[ref];
      if(refObj){
        refObj.classList.add("Test");
        refObj.play();
      }
    },
  },
}
</script>

<style scoped>

</style>
