<template>
  <div class="loading__overlay" v-show="loadingPercent < 100" >
      <div class="loading__item">
        <svg class="" viewBox="0 0 112 112">
          <circle
              stroke="#ffffff"
              fill="transparent"
              r="50"
              cx="56"
              cy="56">
          </circle>
          <circle
              class="progress"
              stroke="#ffffff"
              fill="transparent"
              r="50"
              cx="56"
              cy="56"
              transform="rotate(-90,0,0)"
              :style="{'stroke-dashoffset': progressCircle}">
          </circle>
        </svg>
      </div>
  </div>
</template>

<script>

export default {
  name: "Loader",
  props: ['loops', 'transitions'],
  data: function(){
    return {
      isLoading: true,
      load: 1,
      loadingPercent: 0,
      loadTime: 0,
      interval: null,
      loadedVideos: 0
    }
  },
  mounted(){
    setTimeout(function() {
      this.load = 100;
    }, 1000);
    this.$nextTick(() => {
      let perfData = window.performance.timing;
      let estimatedTime = Math.abs(perfData.loadEventEnd - perfData.navigationStart);
      this.loadTime = parseInt((estimatedTime / 1000) % 60) * 100 ;
      this.doProgress();
    });
  },
  methods: {
    doProgress() {
      let step = this.loadTime / 100;
      this.interval = setInterval(() => {
        this.loadingPercent++
      }, step);
    },
  },
  computed: {
    progressCircle (){
      let loadPercent = ((this.transitions + this.loops) * 100)  / 9 || 0;
      let progress = Math.abs(((314 / 100) * this.loadingPercent) - 314);
      return progress
    },
  },
  watch: {
    loadingPercent(val) {
      if (val >= 100) {
        this.$emit('loaderHide', 1)
        clearInterval(this.interval)
      }
    },
  },
  created() {




  }
}
</script>
