<template>
  <div class="slider__wrapper">
    <div v-show="milestone === 2">
      <div class="slider__container" :class="''" v-show="activeSlider === 1">
        <img rel="preload" src="~@/assets/icons/close.svg" class="slider--close" v-on:click="closeSlider()">

        <swiper ref="slider01" :class="'slider--01 swiper__slider'"  :navigation="true">
          <swiper-slide :key="'s01_01'">
            <div class="slide">
              <div class="slide__content slide__content--01">
                <div class="slide__content--text color-white">
                  <p><strong>1/2 – Die römische Strasse</strong><br>
                    Die Fernstrasse verband die römische Koloniestadt Augusta Raurica – das heutige Augst/Kaiseraugst – mit
                    den wichtigen Städten im Elsass und führte dem Rhein entlang bis an die Nordsee. Bei den Ausgrabungen im
                    Spiegelhof 2018 kamen kiesige Schichten zum Vorschein, die vom Unterbau der Strasse stammen.
                  </p>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide :key="'s01_02'">
            <div class="slide">
              <div class="slide__content slide__content--02">
                <div class="slide__content--text color-white">
                  <p><strong>2/2 – Eine sumpfige Waldlandschaft</strong><br>
                    Die römische Siedlung wurde um 450 n. Chr. wieder aufgegeben. Torfige Sedimente über der römischen
                    Strasse zeugen davon, dass sich die Natur das Gebiet um die Birsigmündung
                    zwischen dem 5. und dem 7. Jahrhundert zurückeroberte. Dank Pollenanalysen wissen wir, dass damals eine
                    sumpfige Waldlandschaft mit Erlen entstand.
                  </p>
                </div>
              </div>
            </div>
          </swiper-slide>
          <button @click="swiper.slideNext()">Slide to the next slide</button>
        </swiper>
      </div>
      <div class="slider__container" :class="slide2Index === 3 ? '' + '' : ''" v-show="activeSlider === 2">
        <img src="~@/assets/icons/close.svg" class="slider--close" v-on:click="closeSlider()">

        <swiper ref="slider02" :class="'slider--02 swiper__slider'" :navigation="true">
          <swiper-slide :key="'s02_01'">
          <div class="slide ">
            <div class="slide__content slide__content--01">
              <div class="slide__content--text">
                <p><strong>1/3 – Eine lange Fundgeschichte</strong><br>
                  Nachdem die Knochenteile vor über 1500 Jahren in den Boden gelangten, konnte der schon in römischer Zeit
                  zerbrochene Unterkiefer
                  wieder zusammengefügt werden. Das hellere Stück rechts stammt aus der Grabung von 1937–39, das dunklere
                  aus derjenigen von 2018.
                </p>
              </div>
            </div>
          </div>
          </swiper-slide>
          <swiper-slide :key="'s02_02'">
          <div class="slide">
            <div class="slide__content slide__content--02">
              <div class="slide__content--text">
                <p><strong>2/3 – Last- oder Reittier?</strong><br>
                  Die ursprünglich aus Zentralasien resp. der arabischen Halbinsel stammenden Tiere wurden in unserer
                  Gegend vermutlich für
                  den Transport von Waren und als Reittiere in der römischen Armee genutzt. Verschiedene spätantike Funde
                  aus Basel zeigen,
                  dass bereits damals Handelskontakte in ferne Länder bestanden.
                </p>
              </div>
            </div>
          </div>
          </swiper-slide>
          <swiper-slide :key="'s02_03'">
          <div class="slide">
            <div class="slide__content slide__content--03">
              <div class="slide__content--text">
                <p><strong>3/3 – Öllampe mit Kameldarstellung</strong><br>
                  Bislang gibt es nur drei weitere Knochenfunde aus römischer Zeit, welche die Anwesenheit von Kamelen und
                  Dromedaren in der Schweiz bestätigen.
                  Häufiger ist die Darstellung der Tiere auf Münzen und Öllämpchen, wie z. B. auf dieser Öllampe aus dem
                  Legionslager Vindonissa (Windisch/AG).
                  Foto: Béla Polyvàs, Kanton Aargau.
                </p>
              </div>
            </div>
          </div>
          </swiper-slide>
        </swiper>

      </div>
    </div>

    <div v-show="milestone === 3">
      <div class="slider__container" v-show="activeSlider === 1">
        <img src="~@/assets/icons/close.svg" class="slider--close" v-on:click="closeSlider()">

        <swiper ref="slider03" :class="'slider--01 swiper__slider'" navigation >
          <swiper-slide :key="'s03_01'">
          <div class="slide">
            <div class="slide__content slide__content--01">
              <div class="slide__content--text slide__content--inverted">
                <p><strong>1/4 – Bergung und Beprobung</strong><br>
                  Ein ca. 1100 Jahre alter Fassboden oder -deckel aus Holz wird für die Beprobung und die Bergung
                  vorbereitet. Die hölzernen Überreste kamen hier auf der Grossgrabung beim Neubau des AUE im Jahr 2019
                  zum Vorschein.
                </p>
              </div>
            </div>
          </div>
          </swiper-slide>
          <swiper-slide :key="'s03_02'">
          <div class="slide">
            <div class="slide__content slide__content--02">
              <div class="slide__content--text">
                <p><strong>2/4 – Recycling von Fässern</strong><br>
                  Für die Wände eines diagonal über die Grabungsfläche laufenden Wasserkanals wurden zwei Dauben
                  (Längshölzer) eines grossen Fasses wiederverwendet. Dank des Feuchtbodens haben sich hier auch ein
                  Baumstamm sowie zwei Pfosten – vermutlich Überreste eines Holzzauns – erhalten.
                </p>
              </div>
            </div>
          </div>
          </swiper-slide>
          <swiper-slide :key="'s03_03'">
          <div class="slide">
            <div class="slide__content slide__content--03">
              <div class="slide__content--text slide__content--inverted">
                <p><strong>3/4 – Holz: ein seltenes Fundmaterial</strong><br>
                  Fässer und Gefässe aus Holz waren in jeder mittelalterlichen Siedlung reichlich vorhanden. Allerdings
                  hat sich das Holz im Verlauf der Zeit meist zersetzt. Die Fundstellen bei der Birsigmündung bilden dank
                  der Feuchtbodenerhaltung eine grosse Ausnahme und ermöglichen einen in Basel einzigartigen Blick auf
                  diese Holzformen. Lebensbild: bunterhund (Zürich) und bildebene (Luzern), © HMB.
                </p>
              </div>
            </div>
          </div>
          </swiper-slide>
          <swiper-slide :key="'s03_04'">
          <div class="slide">
            <div class="slide__content slide__content--04">
              <div class="slide__content--text slide__content--inverted">
                <p><strong>4/4 – Unterteil eines Holzgefässes</strong><br>
                  Das Gefäss wurde bei den Ausgrabungen im Spiegelhof 1937–39 geborgen und weist einen Durchmesser von
                  etwas mehr als 10 Zentimetern auf. Es ist aus einzelnen Dauben (Längshölzer) aus Tannenholz
                  zusammengesetzt. Solche Gefässe dienten im Mittelalter als einfache Trinkgefässe und Schalen.
                </p>
              </div>
            </div>
          </div>
          </swiper-slide>

        </swiper>

      </div>
      <div class="slider__container" v-show="activeSlider === 2">
        <img src="~@/assets/icons/close.svg" class="slider--close" v-on:click="closeSlider()">

        <swiper ref="slider04" :class="'slider--02 swiper__slider'" navigation >
          <swiper-slide :key="'s06_01'">
          <div class="slide ">
            <div class="slide__content slide__content--01">
              <div class="slide__content--text slide__content--inverted">
                <p><strong>1/6 – Überreste alter Holzbauten</strong><br>
                  Beim Bau des neuen Verwaltungsgebäudes der Polizei (Spiegelhof) stiess man unerwartet auf Überreste
                  alter Holzbauten.
                  Diese haben sich im Feuchtboden, der in Basel nur an wenigen Stellen vorkommt, hervorragend erhalten.
                  Foto: SGUF.
                </p>
              </div>
            </div>
          </div>
          </swiper-slide>
          <swiper-slide :key="'s06_02'">
          <div class="slide">
            <div class="slide__content slide__content--02">
              <div class="slide__content--text slide__content--inverted">
                <p><strong>2/6 – Eine Fundstelle von internationaler Bedeutung</strong><br>
                  Die auf der Baustelle des Spiegelhofs vorgefundenen mittelalterlichen Feuchtbodenbefunde waren damals
                  für Basel und die Schweiz einzigartig,
                  aber auch europaweit weitgehend ohne Parallelen. Die Fundstelle ist heute in
                  der Wissenschaft unter dem Namen «Petersberg» bekannt, benannt nach einer verschwundenen Strasse. Foto:
                  Kantonale Denkmalpflege Basel-Stadt.
                </p>
              </div>
            </div>
          </div>
          </swiper-slide>
          <swiper-slide :key="'s06_03'">
          <div class="slide">
            <div class="slide__content slide__content--03">
              <div class="slide__content--text slide__content--inverted">
                <p><strong>3/6 – Stampflehmböden</strong><br>
                  Wohl durch ein Absinken des Grundwasserspiegels trocknete der Boden seit den 1930er-Jahren zunehmend
                  aus. Deshalb zersetzten sich die Holzreste stärker und die Holzerhaltung war bei der Ausgrabung im Jahr
                  2018 deutlich schlechter als 80 Jahre zuvor. Gut erkennbar sind die Holzbauten jedoch an den gelben
                  Stampflehmböden, die sich einst im Innern der Gebäude befanden.
                </p>
              </div>
            </div>
          </div>
          </swiper-slide>
          <swiper-slide :key="'s06_05'">
          <div class="slide">
            <div class="slide__content slide__content--04">
              <div class="slide__content--text">
                <p><strong>4/6 – Funde aus Holz</strong><br>
                  Die erhaltenen Holzobjekte aus den Ausgrabungen im Spiegelhof 1937–39 befinden sich heute im Depot des
                  Historischen Museum Basel (HMB). Zusammen mit dem Labor für Dendroarchäologie Zürich wurden sie neu
                  untersucht. Im Vordergrund ist ein hölzerner Brunnentrog erkennbar.</p>
              </div>
            </div>
          </div>
          </swiper-slide>
          <swiper-slide :key="'s06_06'">
          <div class="slide">
            <div class="slide__content slide__content--05">
              <div class="slide__content--text">
                <p><strong>5/6 – Flechtwerk</strong><br>
                  Bei den Ausgrabungen im Spiegelhof kamen 2018 gut erhaltene Reste eines Flechtwerks zu Tage, auf dem ein
                  Schuhfragment lag. Das ursprünglich wohl als Zaun genutzte Flechtwerk diente vermutlich dazu, das
                  feuchte Terrain zwischen den Häusern zu festigen.
                </p>
              </div>
            </div>
          </div>
          </swiper-slide>
          <swiper-slide :key="'s06_07'">
          <div class="slide">
            <div class="slide__content slide__content--06">
              <div class="slide__content--text">
                <p><strong>6/6 – Das Quartier an der Birsigmündung</strong><br>
                  Die Gebäude, die um 1000 n. Chr. hier standen, waren unterschiedlich konstruiert. Nebst robusten
                  Holzgebäuden gab es auch Schuppen und Unterstände. Die Besiedlung konzentrierte sich entlang der
                  Strassenachse am Birsig und im Bereich der Peterskirche. Das Gebiet dazwischen war nur spärlich
                  besiedelt. Lebensbild: bunterhund (Zürich) und bildebene (Luzern), © HMB.
                </p>
              </div>
            </div>
          </div>
          </swiper-slide>

        </swiper>

      </div>
      <div class="slider__container" v-show="activeSlider === 3">
        <img src="~@/assets/icons/close.svg" class="slider--close" v-on:click="closeSlider()">

        <swiper ref="slider05" :class="'slider--03 swiper__slider'" navigation>
          <swiper-slide :key="'s07_01'">
          <div class="slide ">
            <div class="slide__content slide__content--01">
              <div class="slide__content--text">
                <p><strong>1/7 – Schuhe aus Leder</strong><br>
                  Bei den Ausgrabungen im Spiegelhofareal 1937–39 und 2018 wurden über 1000 Lederfragmente geborgen –
                  hauptsächlich Überbleibsel von Schuhen sowie Verschnitte und Lederabfälle von Schuhmacherarbeiten. Die
                  fragilen Funde wurden nach der Bergung direkt im Labor konserviert, um zu verhindern, dass sich das
                  Leder unter den geänderten klimatischen Bedingungen (Luft anstelle Feuchtboden) zersetzt.
                </p>
              </div>
            </div>
          </div>
          </swiper-slide>
          <swiper-slide :key="'s07_02'">
          <div class="slide">
            <div class="slide__content slide__content--02">
              <div class="slide__content--text">
                <p><strong>2/7 – Die Ausgrabung im Spiegelhof</strong><br>
                  Im Jahr 2018 untersuchte die Archäologische Bodenforschung einen Bereich in der Einstellhalle des
                  Spiegelhofs, der von den Ausgrabungen in den Jahren 1937–39 nicht betroffen war. Dabei wurden erneut
                  Reste der Siedlung aufgedeckt, die hier um 1000 n. Chr. stand.
                </p>
              </div>
            </div>
          </div>
          </swiper-slide>
          <swiper-slide :key="'s07_03'">
          <div class="slide">
            <div class="slide__content slide__content--03">
              <div class="slide__content--text">
                <p><strong>3/7 – Das Gerberhandwerk</strong><br>
                  Das Lebensbild beruht auf Erkenntnissen der beiden Grabungen im Spiegelhofareal, bei denen zahlreiche
                  Lederabfälle sowie Gerbereirohstoffe und -abfälle geborgen wurden. Im Vordergrund werden Fett- und
                  Fleischreste von den Häuten entfernt. Diese wurden danach im Birsig gewaschen und zum Trocknen
                  aufgehängt, bevor man sie in Gerberbottichen weiterverarbeite.
                  Lebensbild: bunterhund (Zürich) und bildebene (Luzern), © HMB.
                </p>
              </div>
            </div>
          </div>
          </swiper-slide>
          <swiper-slide :key="'s07_04'">
          <div class="slide">
            <div class="slide__content slide__content--04">
              <div class="slide__content--text">
                <p><strong>4/7 – Gniedel- oder Glättsteine</strong><br>
                  Solche als Gniedel- oder Glättsteine bezeichneten Glaskuchen wurden in Basel schon verschiedentlich im
                  Zusammenhang mit lederverarbeitendem Handwerk gefunden. Vermutlich dienten sie dazu, das fertig gegerbte
                  Leder geschmeidig zu machen. Möglicherweise wurden sie aber auch zum Imprägnieren des Leders mit Wachs
                  oder zum Glätten von Textilien genutzt.

                </p>
              </div>
            </div>
          </div>
          </swiper-slide>
          <swiper-slide :key="'s07_05'">
          <div class="slide">
            <div class="slide__content slide__content--05">
              <div class="slide__content--text slide__content--inverted">
                <p><strong>5/7 – Ein Stück Leder</strong><br>
                  Im Mittelalter wohnten und arbeiteten Schuhmacher und Gerber oftmals im selben Quartier. Die Lage der
                  Handwerksbetriebe in unmittelbarer Nähe zum Birsig und zu den Wasserquellen im Hangbereich war bewusst
                  gewählt. Wasser war für die Herstellung von Leder ein wichtiger Rohstoff.
                </p>
              </div>
            </div>
          </div>
          </swiper-slide>
          <swiper-slide :key="'s07_06'">
          <div class="slide">
            <div class="slide__content slide__content--06">
              <div class="slide__content--text slide__content--inverted">
                <p><strong>6/7 – Schaf- und Ziegenschädel</strong><br>
                  Auf der Grabung kamen viele Schaf- und Ziegenschädel zum Vorschein. Im Mittelalter wurde den Gerbern die
                  Felle mitsamt dem Schädel geliefert, so dass sie über die Art und das Alter des Tieres Bescheid wussten.
                  Je nach Tierhaut und Verwendungszweck des Leders variierte der nachfolgende Gerbprozess. Die Schädel
                  blieben als Abfall übrig.
                </p>
              </div>
            </div>
          </div>
          </swiper-slide>
          <swiper-slide :key="'s07_07'">
          <div class="slide">
            <div class="slide__content slide__content--07">
              <div class="slide__content--text slide__content--inverted">
                <p><strong>7/7 – Hundekot</strong><br>
                  Bei den Ausgrabungen stiess man auf eine grosse Menge von mineralisiertem Hundekot. Frischer Hundekot
                  wurde beim Gerbprozess verwendet, um besonders geschmeidiges Leder zu erhalten. Gerbereistandorte lagen im
                  Mittelalter meist am Rand der städtischen Siedlungen, da starke Gerüche und viele übelriechende Abfälle in
                  fester und flüssiger Form anfielen. Diese belasteten sowohl die Luft wie auch die Gewässer.
                </p>
              </div>
            </div>
          </div>
          </swiper-slide>
        </swiper>

      </div>
    </div>

    <div v-show="milestone === 4">
      <div class="slider__container" v-show="activeSlider === 1">
        <img src="~@/assets/icons/close.svg" class="slider--close" v-on:click="closeSlider()">

        <swiper ref="slider06" :class="'slider--01 swiper__slider'" navigation >
          <swiper-slide :key="'s04_01'">
          <div class="slide">
            <div class="slide__content slide__content--01">
              <div class="slide__content--text">
                <p><strong>1/4 – Die Mauer im Untergeschoss</strong><br>
                  Die zugehauenen Steinquader zeugen von zusätzlichem Aufwand bei den Steinmetzarbeiten und damit höheren
                  Baukosten im Vergleich zum üblicherweise verwendeten Mauerwerk aus Bruchsteinen. Nur vermögende
                  Bauherren konnten sich dies leisten.
                </p>
              </div>
            </div>
          </div>
          </swiper-slide>
          <swiper-slide :key="'s04_02'">
          <div class="slide">
            <div class="slide__content slide__content--02">
              <div class="slide__content--text">
                <p><strong>2/4 – Ein repräsentativer Steinbau</strong><br>
                  Die erhaltene Mauer befand sich im Fundamentbereich eines frühen repräsentativen Steinbaus, der hier um
                  1250 stand. Hinter der Mauer befand sich ein Keller. In der Neuzeit wurde ein Abwasserkanal durch die
                  Mauer geführt </p>
              </div>
            </div>
          </div>
          </swiper-slide>
          <swiper-slide :key="'s04_03'">
          <div class="slide">
            <div class="slide__content slide__content--03">
              <div class="slide__content--text slide__content--inverted">
                <p><strong>3/4 – Bossen- und Buckelquader</strong><br>
                Die Mauer wurde aus sauber gefügten Mauerquadern errichtet, die Bossen- oder Buckelquader genannt werden.
                Dabei wurde die sichtbare Seite der einzelnen Mauerquader bewusst als Buckel vorstehend belassen, während
                die Ränder glatt zugehauen wurden.</p></div>
            </div>
          </div>
          </swiper-slide>
          <swiper-slide :key="'s04_04'">
          <div class="slide">
            <div class="slide__content slide__content--04">
              <div class="slide__content--text slide__content--inverted">
                <p><strong>4/4 – Vergrösserung des Kellers</strong><br>
                  Am linken Bildrand befindet sich die Rückseite der Bossenquadermauer. Die Mauerschale wurde wohl im
                  18./19. Jahrhundert abgebrochen und der Mauerkern neu verputzt. So konnte der Kellerraum leicht
                  vergrössert werden.</p>
              </div>
            </div>
          </div>
          </swiper-slide>
        </swiper>

      </div>
      <div class="slider__container" v-show="activeSlider === 2">
        <img src="~@/assets/icons/close.svg" class="slider--close" v-on:click="closeSlider()">

        <swiper ref="slider07" :class="'slider--02 swiper__slider'" navigation>
          <swiper-slide :key="'s08_01'">
          <div class="slide ">
            <div class="slide__content slide__content--01">
              <div class="slide__content--text">
                <p><strong>1/1 Tonfigur, Oberkörper eines Kindes (?)</strong><br>
                  Das Fragment einer mittelalterlichen Tonfigur kam 2018 bei den Ausgrabungen im benachbarten Spiegelhof
                  zum Vorschein. Solche Tonfiguren dienten oftmals als Spielzeug. Denkbar wäre aber auch eine Aufstellung
                  als Heiligenfigur in einer privaten Andachtsnische.
                </p>
              </div>
            </div>
          </div>
          </swiper-slide>
        </swiper>

      </div>
      <div class="slider__container" v-show="activeSlider === 3">
        <img src="~@/assets/icons/close.svg" class="slider--close" v-on:click="closeSlider()">

        <swiper ref="slider08" :class="'slider--03 swiper__slider'" navigation>
          <swiper-slide :key="'s09_01'">
          <div class="slide ">
            <div class="slide__content slide__content--01">
              <div class="slide__content--text slide__content--inverted">
                <p><strong>1/1 – Eine stinkende Kloake</strong><br>
                  Noch bis ins 19. Jahrhundert grenzten viele Häuser mit ihren Rückseiten direkt an den offen fliessenden
                  Birsig. Abfälle und Fäkalien liessen sich somit problemlos aus Fenstern und Plumpsklos direkt im Fluss
                  entsorgen. Bei niedrigem Wasserstand führte das zu üblen Gerüchen und war Grund für wiederkehrende
                  Typhus- und Choleraepidemien. Foto: ETH-Bibliothek Zürich, E-Pics: Ans_05459-012-AL-FL.
                </p>
              </div>
            </div>
          </div>
          </swiper-slide>
        </swiper>

      </div>
    </div>

    <div v-show="milestone === 5">
      <div class="slider__container" v-show="activeSlider === 1">
        <img src="~@/assets/icons/close.svg" class="slider--close" v-on:click="closeSlider()">

        <swiper ref="slider09" :class="'slider--01 swiper__slider'" navigation >
          <swiper-slide :key="'s05_01'">
          <div class="slide">
            <div class="slide__content slide__content--01">
              <div class="slide__content--text slide__content--inverted">
                <p><strong>1/4 Im Keller liegen geblieben</strong><br>
                  In der Verfüllung des Kellerraums, der hinter der Bossenquadermauer lag, konnte die Archäologische
                  Bodenforschung viele Funde bergen. Blick auf die Rückseite der Bossenquadermauer im Innern des Gebäudes.
                  Auf der Mauer befinden sich die Fundkisten der Archäologen.
                </p>
              </div>
            </div>
          </div>
          </swiper-slide>
          <swiper-slide :key="'s05_02'">
          <div class="slide">
            <div class="slide__content slide__content--02">
              <div class="slide__content--text">
                <p><strong>2/4 Blick in die Fundkisten</strong><br>
                  Unter den Funden aus dem Keller befanden sich mehrere ganze Flaschen. Es handelte sich dabei um Parfum-
                  und Haarwasserflaschen, die aus den Beständen des Coiffeursalons stammen dürften, der sich bis zum
                  Abbruch der Gebäude zu Anfang des 20. Jahrhunderts hier befand.</p>
              </div>
            </div>
          </div>
          </swiper-slide>
          <swiper-slide :key="'s05_03'">
          <div class="slide">
            <div class="slide__content slide__content--03">
              <div class="slide__content--text">
                <p><strong>3/4 Parfüm aus Paris</strong><br>
                  Die rund 17 cm hohe Flasche mit Blumendekor stammt aus der Pariser Parfümerie von Édouard Pinaud. Der
                  Blumendekor erinnert entweder an den Hausnamen der Parfümerie in Paris «A la Corbeille Fleurie» oder an
                  das Parfüm «Bouquet de la Reine Victoria» zu Ehren der gleichnamigen Königin. Parfüms von Pinaud wurden
                  an Weltausstellungen in den 1850er und 1860er Jahren mehrfach ausgezeichnet.
                </p>
              </div>
            </div>
          </div>
          </swiper-slide>
          <swiper-slide :key="'s05_04'">
          <div class="slide">
            <div class="slide__content slide__content--04">
              <div class="slide__content--text">
                <p><strong>4/4 Haarwasser der Firma Rausch</strong><br>
                  Mehrere transparente Flaschen sind seitlich mit dem Schriftzug «J. W. RAUSCH KONSTANZ» versehen. Rausch
                  entwickelte die erste flüssige Haarwaschseife. Auch die gefundenen Flaschen dürften einst dieses
                  Haarmittel enthalten haben.</p>
              </div>
            </div>
          </div>
          </swiper-slide>
        </swiper>

      </div>
      <div class="slider__container" v-show="activeSlider === 2">
        <img src="~@/assets/icons/close.svg" class="slider--close" v-on:click="closeSlider()">

        <swiper ref="slider10" :class="'slider--02 swiper__slider'" navigation>
          <swiper-slide :key="'s10_01'">
          <div class="slide ">
            <div class="slide__content slide__content--01">
              <div class="slide__content--text slide__content--inverted">
                <p><strong>1/1 Der Birsig wird überwölbt</strong><br>
                  Ab 1898 wurde der Birsig begradigt und mit einem Gewölbe versehen. Damit sollte sowohl dem vermehrten
                  Verkehrsaufkommen Rechnung getragen als auch die sanitären Zustände entlang des Birsigs verbessert
                  werden. Der neue Fahrdamm lag wegen des Gewölbes höher als die alten Gassen, weshalb umfangreiche
                  Aufschüttungen erforderlich waren. Foto: StaBS AL 45, 2-10-2.</p>
              </div>
            </div>
          </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>

// Import Swiper Vue.js components
//import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

import SwiperCore, { Navigation } from "swiper/core";
SwiperCore.use([Navigation]);
// Import Swiper styles
import 'swiper/css';
import 'swiper/scss/navigation';
import {ref} from "vue";
export default {
  name: 'SliderContainer',
  props: ['milestone', 'activeSlider'],
  components: {
    Swiper,
    SwiperSlide,
  },
  mounted(){
    this.$nextTick(() => {
      document.querySelectorAll('.slide__content--text').forEach(item => {
        item.addEventListener('click', event => {
          item.classList.toggle('active');
        })
      })
    })
  },
  methods:{
    closeSlider(){
      this.$emit('closeSlider');
    },
    onSlideChange(){

    }
  }
}
</script>

<style scoped>

</style>
