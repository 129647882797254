<template>
  <div class="nav__container">
    <nav v-show="milestone !== 0" :class="showTransitionVideo ? 'disable--interaction' : ''">
      <ul>
        <li class="nav--introduction testMilestone" :class="milestone === 1 && noTransition ? 'active' : ''">
          <a v-on:click="setMilestine(1)">Einführung</a>
        </li>
        <li class="testMilestone" :class="milestone === 2 && noTransition ? 'active' : ''">
          <a v-on:click="setMilestine(2)"> um 300–450 n. Chr.</a>
          <br>
          <span class="subline">Eine römische Siedlung</span>
        </li>
        <li class="testMilestone" :class="milestone === 3 && noTransition ? 'active' : ''">
          <a v-on:click="setMilestine(3)">um 1000 n. Chr.</a>
          <br>
          <span class="subline">Ein Stadtquartier entsteht</span>
        </li>
        <li class="testMilestone" :class="milestone === 4 && noTransition ? 'active' : ''">
          <a v-on:click="setMilestine(4)">um 1250 n. Chr.</a>
          <br>
          <span class="subline">Ein repräsentativer Steinbau</span>
        </li>
        <li class="testMilestone" :class="milestone === 5 && noTransition ? 'active' : ''">
          <a v-on:click="setMilestine(5)">um 1900 n. Chr.</a>
          <br>
          <span class="subline">Das neue Gesicht der Stadt</span>
        </li>

      </ul>
    </nav>

    <div class="controls__container" v-show="milestone !== 0">
      <div class="controls">
        <div class="controls__button sound">
          <img src="~@/assets/icons/mute.svg" alt="" v-on:click="toggleMute(false)" v-if="volume === 0" rel="preload">
          <img src="~@/assets/icons/sound.svg" alt="" v-on:click="toggleMute(true)" v-else rel="preload">

        </div>
        <div class="controls__button controls__button--pause" v-if="showTransitionVideo" v-on:click="pauseVideoTransition()">
          <img src="~@/assets/icons/pause.svg" alt="" v-if="transitionPlaying" rel="preload">
          <img src="~@/assets/icons/play.svg" alt="" v-if="!transitionPlaying" rel="preload">
        </div>
        <div class="controls__button controls__button--next" v-on:click="startVideoTransition()" v-if="milestone !== 5">
          <img rel="preload" src="~@/assets/icons/arrow_right.svg" alt="">
        </div>
        <div class="controls__button controls__restart" v-if="milestone === 5 && (!transitionPlaying || !showTransitionVideo)">
          <div v-on:click="startVideoTransition()" class="restart">Restart</div>
        </div>
      </div>
    </div>

    <div class="controls__intro" v-show="milestone === 0 && (!transitionPlaying || !showTransitionVideo)">
     <a class="intro--start"  v-on:click="startModule">Start</a>

    </div>

  </div>

  <div class="main--legend" v-if="milestone != 3 && milestone != 4 && showTransitionVideo">
    <p>Google Earth – GeoBasis-DE/BKG (©2009)</p>
  </div>
  <div class="impressum impressum-text" v-if="milestone === 5 && (!transitionPlaying || !showTransitionVideo)" v-on:click="openImpressum">
    Impressum
  </div>
  <div class="impressum__overlay" v-show="impressumOpen">
    <div class="impressum__close" v-on:click="openImpressum"></div>
    <div class="impressum__overlay--inner">
      <section class="s01">
        <h3 class="headline">Wir sichern der Vergangenheit</h3>
        <h3 class="headline__sub">die Zukunft</h3>

        <p>Die Archäologische Bodenforschung Basel-Stadt ist eine kantonale Fachstelle. Sie setzt sich für die Sicherung und die Pflege des archäologischen Erbes des Kantons ein.</p>
      </section>
      <section class="s02">
        <p>Die vor Ort konservierte Mauer bildet das Herzstück der Archäologischen Informationsstelle «BASEL, 300–1900 n. CHR.: DIE BIRSIGMÜNDUNG – VON DER RÖMISCHEN SIEDLUNG ZUM MODERNEN STADTQUARTIER». Die hier gezeigte mediale Präsentation sowie in einer Vitrine ausgestellte Funde illustrieren vor Ort die Siedlungsgeschichte im Gebiet der Birsigmündung.
          <br><a href="https://www.archaeologie.bs.ch/vermitteln/info-stellen/spiegelgasse.html" target="_blank">Mehr erfahren</a></p>
      </section>
      <section class="s03">
        <p>Eine Zeitreise durch 3000 Jahre Basel. Die App der Archäologischen Bodenforschung «Archaeo Tour».</p>
        <a class="btn" href="https://play.google.com/store/apps/details?id=ch.basel.archaeotour" target="_blank">Google
          Play</a>
        <a class="btn" href="https://itunes.apple.com/ch/app/archaologischer-rundgang/id998942740?mt=8" target="_blank">App
          Store</a>

      </section>
      <section class="s04">
        <p>Archäologische Bodenforschung<br>
          des Kantons Basel-Stadt<br>
          Petersgraben 11, 4051 Basel<br>
          +41 61 267 23 55<br>
          <a href="mailto:arch.bodenforschung@bs.ch">arch.bodenforschung@bs.ch</a></p>
        <p><a href="http://www.archaeologie.bs.ch">www.archaeologie.bs.ch</a></p>

        <div class="social__media">
          <a href="https://www.facebook.com/archaeologie.bs/" target="_blank">
            <img src="~@/assets/icons/facebook.svg" class="img-fluid logo-facebook">
          </a>
          <a href="https://www.instagram.com/archaeologische_bodenforschung/" target="_blank">
            <img src="~@/assets/icons/insta__white.svg" class="img-fluid logo-instagram">
          </a>
        </div>
      </section>
    </div>

  </div>
  <div class="caption main--legend" v-if="milestone === 5 && (!transitionPlaying || !showTransitionVideo)">
    <p>Foto: StaBS AL 45, 4-23</p>
  </div>
  <div class="nav__shadow"
       :class="[showTransitionVideo === true ? 'nav__shadow--foreground' : '', milestone === 3 ? 'nav__shadow--darker' : '']">
  </div>
  <transition name="fade">
  <div class="transition__container" v-show="showTransitionVideo">

    <div class="show__desktop" v-if="!isMobile">
      <video ref="transitionVideo01" @ended="videoEnded()" @loadedmetadata="loadVideo" playsinline v-show="milestone === 2">
        <source src="~@/assets/video/01_transition.mp4">
      </video>
      <video ref="transitionVideo02" @ended="videoEnded()" @loadedmetadata="loadVideo" playsinline v-show="milestone === 3">
        <source src="~@/assets/video/02_transition.mp4">
      </video>
      <video ref="transitionVideo03" @ended="videoEnded()" @loadedmetadata="loadVideo" playsinline v-show="milestone === 4">
        <source src="~@/assets/video/03_transition.mp4" >
      </video>
      <video ref="transitionVideo04" @ended="videoEnded()" @loadedmetadata="loadVideo" playsinline v-show="milestone === 5">
        <source src="~@/assets/video/04_transition.mp4">
      </video>
      <video ref="transitionVideo05" @ended="videoEnded()" @loadedmetadata="loadVideo" playsinline v-show="milestone === 1">
        <source src="~@/assets/video/05_transition.mp4">
      </video>
    </div>
    <div class="show__mobile" v-if="isMobile">
      <video ref="mobiletransitionVideo01" @ended="videoEnded()" @loadedmetadata="loadVideo" playsinline v-show="milestone === 2">
        <source src="~@/assets/video/mobile/01_transition.mp4">
      </video>
      <video ref="mobiletransitionVideo02" @ended="videoEnded()" @loadedmetadata="loadVideo" playsinline v-show="milestone === 3">
        <source src="~@/assets/video/mobile/02_transition.mp4">
      </video>
      <video ref="mobiletransitionVideo03" @ended="videoEnded()" @loadedmetadata="loadVideo" playsinline v-show="milestone === 4">
        <source src="~@/assets/video/mobile/03_transition.mp4" >
      </video>
      <video ref="mobiletransitionVideo04" @ended="videoEnded()" @loadedmetadata="loadVideo" playsinline v-show="milestone === 5">
        <source src="~@/assets/video/mobile/04_transition.mp4">
      </video>
      <video ref="mobiletransitionVideo05" @ended="videoEnded()" @loadedmetadata="loadVideo" playsinline v-show="milestone === 1">
        <source src="~@/assets/video/mobile/05_transition.mp4">
      </video>
    </div>
  </div>
  </transition>
  <div ref="audioTrigger" v-on:click="startIntroSound"></div>

</template>

<script>
import {Howl, Howler} from 'howler';
import Intro from '@/assets/audio/ABBS_Intro.mp3';
import Loop_02 from '@/assets/audio/02_RoemischeSiedlung_Loop.mp3';
import Loop_03 from '@/assets/audio/03_Stadtquartier_Loop.mp3';
import Loop_04 from '@/assets/audio/04_Steinbau_Loop.mp3';
import Loop_05 from '@/assets/audio/05_NeueGesicht_Loop.mp3';
import Transition_01 from '@/assets/audio/01_transition.mp3';
import Transition_02 from '@/assets/audio/02_transition.mp3';
import Transition_03 from '@/assets/audio/03_transition.mp3';
import Transition_04 from '@/assets/audio/04_transition.mp3';
import Transition_05 from '@/assets/audio/05_transition.mp3';
export default {
  name: 'Navigation',
  props: ['milestone'],
  data(){
    return{
      impressumOpen: false,
      noTransition: true,
      showTransitionVideo: false,
      loops: {
        c02 : null,
        c03 : null,
        c04 : null,
        c05 : null,
      },
      transitions: {
        t01 : null,
        t02 : null,
        t03 : null,
        t04 : null,
        t05 : null,
      },
      intro: null,
      volume: 1,
      currentSound: null,
      transitionPlaying: false,
    }
  },
  mounted() {
    this.intro = new Howl({
      src: Intro,
      loop: true,
      autoplay: false,
      volume: this.volume,
    });

    this.loops.c02 = new Howl({
      src: Loop_02,
      loop: true,
      autoplay: false,
      volume: this.volume
    });

    this.loops.c03 = new Howl({
      src: Loop_03,
      loop: true,
      autoplay: false,
      volume: this.volume
    });
    this.loops.c04 = new Howl({
      src: Loop_04,
      loop: true,
      autoplay: false,
      volume: this.volume
    });
    this.loops.c05 = new Howl({
      src: Loop_05,
      loop: true,
      autoplay: false,
      volume: this.volume
    });
    this.transitions.t01 = new Howl({
      src: Transition_01,
      autoplay: false,
      volume: this.volume
    });
    this.transitions.t02 = new Howl({
      src: Transition_02,
      autoplay: false,
      volume: this.volume
    });
    this.transitions.t03 = new Howl({
      src: Transition_03,
      autoplay: false,
      volume: this.volume
    });
    this.transitions.t04 = new Howl({
      src: Transition_04,
      autoplay: false,
      volume: this.volume
    });
    this.transitions.t05 = new Howl({
      src: Transition_05,
      autoplay: false,
      volume: this.volume
    });
  },
  methods: {
    toggleMute(shouldMute){
      if(shouldMute){
        this.volume = 0;
      } else {
        this.volume = 1;
      }
      this.currentSound.volume(this.volume);
    },
    startModule(){
      this.$emit('changeMilestone', 1);
      this.setMilestine(1)
      //this.currentSound.pause();
    },
    restartModule(){
      this.$emit('changeMilestone', 0);
    },
    setMilestine(milestone) {
      if (this.currentSound !== null) {
        this.currentSound.fade(this.volume, 0, 300)
      }
      this.$emit('changeMilestone', milestone);
      this.startLoopSound(milestone);
    },
    startLoopSound(i) {
      console.log("Start Loopsound", i)
      switch (i) {
        case 1:
          this.intro.play()
          this.intro.seek(0).fade(0, this.volume, 1000);
          this.currentSound = this.intro;
          break;
        case 2:
          this.loops.c02.play()
          this.loops.c02.seek(0).fade(0, this.volume, 1000);
          this.currentSound = this.loops.c02;
          break;
        case 3:
          this.loops.c03.play()
          this.loops.c03.seek(0).fade(0, this.volume, 1000);
          this.currentSound = this.loops.c03;
          break;
        case 4:
          this.loops.c04.play()
          this.loops.c04.seek(0).fade(0, this.volume, 1000);
          this.currentSound = this.loops.c04;
          break;
        case 5:
          this.loops.c05.play()
          this.loops.c05.seek(0).fade(0, this.volume, 1000);
          this.currentSound = this.loops.c05;
          break;
      }

    },
    pauseVideoTransition() {
      let refString = 'transitionVideo0'+ (this.milestone -1);
      if (window.innerWidth < 1024) {
        refString = 'mobiletransitionVideo0'+ (this.milestone -1);
      }

      if (this.showTransitionVideo) {
        if (this.transitionPlaying) {
          this.currentSound.pause();
          this.$refs[refString].pause();
          this.transitionPlaying = false;
        } else {
          this.currentSound.play();
          this.$refs[refString].play();
          this.transitionPlaying = true;
        }
      }
    },
    startVideoTransition(){
      if(!this.showTransitionVideo){
        if (this.currentSound !== null) {
          this.currentSound.fade(this.volume, 0, 1000);
        }

        let refString = 'transitionVideo0'+ this.milestone;
        if (window.innerWidth < 1024) {
          refString = 'mobiletransitionVideo0'+ this.milestone;
        }
        this.showTransitionVideo = true;
        this.transitionPlaying = true;
        this.$emit('startVideoTransition');

        switch (this.milestone) {
          case 1:
            this.transitions.t01.play()
            this.transitions.t01.fade(0, this.volume, 1000);
            this.currentSound = this.transitions.t01;
            break;
          case 2:
            this.transitions.t02.play()
            this.transitions.t02.fade(0, this.volume, 1000);
            this.currentSound = this.transitions.t02;
            break;
          case 3:
            this.transitions.t03.play()
            this.transitions.t03.fade(0, this.volume, 1000);
            this.currentSound = this.transitions.t03;
            break;
          case 4:
            this.transitions.t04.play()
            this.transitions.t04.fade(0, this.volume, 1000);
            this.currentSound = this.transitions.t04;
            break;
          case 5:
            this.transitions.t05.play()
            this.transitions.t05.fade(0, this.volume, 1000);
            this.currentSound = this.transitions.t05;
            break;
        }
        this.$refs[refString].play();
        this.currentSound.seek(0).fade(0, this.volume, 1000);
        this.currentSound.play();

        if(this.milestone === 5){
          this.$emit('changeMilestone', 1);
        } else {
          this.$emit('changeMilestone', this.milestone + 1);
        }

      }

    },
    videoEnded(){

      this.$emit('endVideoTransition');
      let _this = this;
      if(this.milestone != 1){
        setTimeout(function() {
          _this.transitionPlaying = false;
          _this.showTransitionVideo = false;
          _this.startLoopSound(_this.milestone);
          _this.currentSound.play();
        }, 3000);
      } else {
        _this.transitionPlaying = false;
        _this.showTransitionVideo = false;
        _this.startLoopSound(_this.milestone);
        _this.currentSound.play();
      }
      console.log(this.milestone)




    },
    startIntroSound(){

      this.intro.play()
      this.intro.seek(0).fade(0, this.volume, 1000);
      this.currentSound = this.intro;
    },
    loadVideo(){
      this.$emit('videosLoaded')
    },
    openImpressum(){
      this.impressumOpen = !this.impressumOpen;
    }
  },
  computed: {
    isMobile(){
      let isMobile = false;
      if (window.innerWidth < 1024) {
        isMobile = true;
      }
      return isMobile
    }
  },
}
</script>


