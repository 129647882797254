<template>
  <info-container></info-container>
  <Navigation @endVideoTransition="transitionPlaying = false" @startVideoTransition="transitionPlaying = true" :milestone="currentMilestone" @changeMilestone="changeMilestone" @videosLoaded="transitionLoaded++"/>
  <video-container :milestone="currentMilestone" @videosLoaded="loopsLoaded++" v-show="!transitionPlaying"></video-container>
  <dot-container :transition="transitionPlaying" :milestone="currentMilestone"></dot-container>
  <loader :loops="loopsLoaded" :transitions="transitionLoaded"></loader>

</template>

<script>

import InfoContainer from '@/components/InfoContainer'
import VideoContainer from '@/components/VideoContainer'
import DotContainer from '@/components/DotContainer'
import Navigation from '@/components/Navigation'
import Loader from "@/components/Loader";
export default {
  name: 'App',
  components: {
    InfoContainer,
    VideoContainer,
    DotContainer,
    Navigation,
    Loader
  },
  data(){
    return{
      currentMilestone: 0,
      transitionPlaying: false,
      loopsLoaded: 0,
      transitionLoaded: 0
    }
  },
  mounted() {
    this.$nextTick(() => {
      const body = document.querySelector('body');
      body.scrollTo(0,1)
    })
  },
  methods: {
    changeMilestone(milestone){
      this.currentMilestone = milestone;
    },

  }
}
</script>
