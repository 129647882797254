<template>
  <div class="landscape__info">
    <p>Bitte drehen Sie Ihr Smartphone / Tablet oder maximieren Sie Ihr Browserfenster</p>
  </div>
  <!--<div class="fullscreen__info" v-if="!isFullscreen">
    <p>Klicken Sie auf Vollbildmodus.</p>
    <div class="mt-5 button" v-on:click="toggleFullscreen()">Vollbildmodus</div>
  </div>-->
</template>

<script>
export default {
  name: 'InfoContainer',
  data: function () {
    return {
      isFullscreen: false,
    }
  },
  methods: {
    toggleFullscreen() {
      let element = document.getElementsByTagName("body")[0];

      if (!document.fullscreenElement) {
        this.isFullscreen = true;
        if (element.requestFullScreen) {
          element.requestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        }
      } else {
        document.exitFullscreen();
      }
    }
  }
}
</script>

<style scoped>

</style>
